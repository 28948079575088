.ce-iframe {
  background: $c-gray-5;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  &__title {
    font-size: $f-medium;
    line-height: $lh-small;
    margin-bottom: .5rem;

    @include breakpoint(medium) {
      font-size: $f-large;
      line-height: $lh-medium;
      margin-bottom: 1rem;
    }

    @include breakpoint(xlarge) {
      font-size: $f-xlarge;
      line-height: $lh-large;
      margin-bottom: 2rem;
    }
  }

  /* Then style the iframe to fit in the container div with full height and width */
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

    .cookieconsent {
        &-optout-marketing {

            padding: 1.5rem 2rem;

            color: $c-white;

            background-color: $c-red;
        }

        &__title {

            margin: 0;

            font-size: $f-small;
            font-weight: 600;
            line-height: $lh-xsmall;
        }

        &__text {

            margin: 0;

            font-size: $f-xsmall;
            font-weight: $fw-normal;
            line-height: $lh-xsmall;

            a {

                color: $c-white;
                text-decoration: underline;
            }
        }
    }
}
