.ce-bodytext {

  ol,
  ul {
    line-height: inherit;
    margin-bottom: 1rem;

    @include breakpoint(xlarge) {
      margin-bottom: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    li {
      background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M21.6333%204.20368C22.0607%204.55346%2022.1237%205.18349%2021.7739%205.61089L10.573%2019.2977C9.75655%2020.2954%208.22325%2020.2713%207.43849%2019.2484L2.2066%2012.4292C1.87042%2011.991%201.9531%2011.3632%202.39128%2011.0271C2.82946%2010.6909%203.4572%2010.7736%203.79338%2011.2117L9.02527%2018.031L20.2261%204.34424C20.5759%203.91684%2021.2059%203.85391%2021.6333%204.20368Z%22%20fill%3D%22%23FF0000%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat left top;
      padding-left: 3rem;

      @include breakpoint(medium) {
        background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M21.6333%204.20368C22.0607%204.55346%2022.1237%205.18349%2021.7739%205.61089L10.573%2019.2977C9.75655%2020.2954%208.22325%2020.2713%207.43849%2019.2484L2.2066%2012.4292C1.87042%2011.991%201.9531%2011.3632%202.39128%2011.0271C2.82946%2010.6909%203.4572%2010.7736%203.79338%2011.2117L9.02527%2018.031L20.2261%204.34424C20.5759%203.91684%2021.2059%203.85391%2021.6333%204.20368Z%22%20fill%3D%22%23FF0000%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat left .125rem;
      }
    }
  }

  ol {
    list-style-position: inside;
  }

  a {
    &:not(.button) {
      color: $c-black;
      text-decoration: none;
      border-bottom: .0625rem solid $c-black;
      transition: border-color $t-animation-fast ease;

      &:hover {
        text-decoration: none;
        border-color: transparent;
      }
    }
  }

  p {
    line-height: inherit;
    margin-bottom: 1rem;

    @include breakpoint(xlarge) {
      margin-bottom: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}