.ajax-loader {
  width: 3rem;
  height: 3rem;
  position: absolute;
  background: $c-white;
  z-index: 100;
  border-radius: 50%;
  display: none;
  overflow: hidden;

  &.is-visible {
    display: block;
  }
}