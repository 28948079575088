// sass-lint:disable no-important
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  /* Fix for "object-fit: cover" not working on IE11 */
  .tt-address {

    &__list {

      &__item {

        .image {
          margin: 0 !important;
          position: relative !important;
          max-height: 100% !important;
          max-width: none !important;
          height: 100% !important;
          width: auto !important;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
          z-index: 100;

          &-wrap {
            overflow: hidden;
          }
        }
      }
    }
  }
}
// sass-lint:enable no-important
