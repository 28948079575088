.ce-benefits {
    background: $c-gray-5;
    padding: 2.5rem 0;

    @include breakpoint(medium) {
        padding: 5rem 0;
    }

    @include breakpoint(xlarge) {
        padding: 6rem 0;
    }

  &__title {
    font-size: $f-medium;
    line-height: $lh-small;
    margin-bottom: .5rem;

    @include breakpoint(medium) {
      font-size: $f-large;
      line-height: $lh-medium;
      margin-bottom: 1rem;
    }

    @include breakpoint(xlarge) {
      font-size: $f-xlarge;
      line-height: $lh-large;
      margin-bottom: 2rem;
    }
  }


    &__item {
        &__image {
            margin-bottom: 1rem;
            height: 6rem;
            width: 6rem;

            @include breakpoint(medium) {
                height: 7.5rem;
                width: 7.5rem;
            }

            @include breakpoint(xlarge) {
                height: 10rem;
                width: 10rem;
            }
        }

        &__title {
            font-weight: $fw-bold;
            font-size: $f-small;
            line-height: $lh-xsmall;

            @include breakpoint(xlarge) {
                font-size: $f-medium;
                line-height: $lh-small;
            }
        }

        &__text {
            margin-top: .25rem;
            font-size: $f-small;
            line-height: $lh-xsmall;

            @include breakpoint(xlarge) {
                margin-top: .5rem;
            }
        }
    }
}
