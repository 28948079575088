.employee {
  &-headline {
    margin: 0 0 2rem;
  }

  &-image {
    margin-bottom: 16px;

    .image {
      height: auto;
      width: 100%;
      max-width: 160px;

      @include breakpoint(xlarge) {
        max-width: 300px;
      }
    }
  }

  &-name {

    margin: 0 0 .5rem;

    font-size: 24px;
    line-height: 32px;
  }

  &-position {

    margin: 0 0 1rem;

    color: $c-gray-50;
    font-size: 18px;
    line-height: 24px;
  }

  &-address {

    font-size: 18px;
    line-height: 24px;

    .address {
      &-plz {

        margin: 0 .5rem 0 0;
      }
    }
  }

  &-phone {

    font-size: 18px;
    line-height: 24px;
  }

  &-mail {

    font-size: 18px;
    line-height: 24px;

    color: $c-black;
    text-decoration: none;
    border-bottom: .0625rem solid $c-black;
    transition: border-color $t-animation-fast ease;

    &:hover {
      text-decoration: none;
      border-color: transparent;
    }
  }
}
