/* Add this file to your scss modules and configure your styles */

/* Variables -> Add your SCSS Variables here */
$cookie-background: rgba(51, 51, 51, .97);
$cookie-textcolor: #fff;
$cookie-basepadding: 20px;

/* Cookiebar Wrapper General */
.cookiebar {
	background: $cookie-background;
	color: $cookie-textcolor;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	padding: $cookie-basepadding;
	max-height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;

	@media only screen and (min-width: 768px) {
		width: 60vw;
	}

	@media only screen and (min-width: 1180px) {
		width: 40vw;
	}

	&.is-closed {
		display: none;
	}

	/* Title */
	&__title {
		color: $cookie-textcolor;
		font-size: $f-medium;
		line-height: $lh-small;

		@include breakpoint(xlarge) {
			font-size: $f-large;
			line-height: $lh-medium;
		}
	}

	/* Bodytext */
	&__bodytext {
		color: $cookie-textcolor;
		margin-bottom: 1.5rem;
		font-size: $f-xxxsmall;
		line-height: $lh-xsmall;

		@include breakpoint(medium) {
			font-size: $f-xxsmall;
		}

		@include breakpoint(xlarge) {
			font-size: $f-small;
		}

		&--last {
			margin-bottom: 0;
		}
	}

	&__button-wrap {
		margin-bottom: 1rem;
	}

	/* Textbutton / Link */
	&__textlink {
		color: $c-white;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&__link {
		color: $c-gray-30;
		border-color: $c-gray-30;

		&:hover,
		&:focus,
		&:active {
			color: $c-gray-30;
			border-color: $c-gray-30;
		}
	}

	/* Checkbox */
	&__checkbox {
		&-container {
			display: flex;
			flex-flow: row wrap;
		}

		&-wrap {
			flex-basis: 100%;
			flex-grow: 0;
			margin-bottom: 20px;

			@media only screen and (min-width: 768px) {
				flex-basis: 50%;
			}
		}
	}

	/* Accordeon */
	&__accordeon {
		$self: &;

		&__trigger {
			.icon {
				transition: left .2s ease, transform .2s ease;

				svg {
					path {
						fill: $c-gray-30;
					}
				}
			}
		}

		&__content {
			display: none;
		}

		&.is-open {
			#{ $self }__content {
				display: block;
			}

			#{ $self }__trigger {
				margin-bottom: 20px;

				.icon {
					transform: rotate(180deg);
				}
			}
		}
	}

	/* History */
	&__history {
		&__item {
			display: flex;
			flex-flow: row wrap;

			.date {
				flex-basis: 100%;
				flex-grow: 0;
			}

			.category,
			.choice {
				flex-basis: 50%;
				flex-grow: 0;
				word-break: break-word;
			}
		}
	}

	/* Close */
	&__close {
		position: absolute;
		right: 20px;
		top: 20px;
		z-index: 2;
		cursor: pointer;
		line-height: 0;
	}
}
