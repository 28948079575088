.page-content {
  display: block; // Fix for IE11
  width: 100%;    // Fix for IE11
  padding-top: 4.5rem;
  position: relative;
  z-index: 1;
  transition: padding-top .3s ease;

  @include breakpoint(large) {
    padding-top: 6rem;
  }

  .frame {
    margin-bottom: 2.5rem;

    @include breakpoint(medium) {
      margin-bottom: 5rem;
    }

    @include breakpoint(xlarge) {
      margin-bottom: 6rem;
    }
  }

    [id] {
        scroll-margin-top: 4.5rem;

        @include breakpoint(large) {
            scroll-margin-top: 6rem;
        }
    }
}
