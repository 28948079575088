.pagebrowser {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__item {
    margin-right: 1rem;

    &.last {
      margin-right: 0;
    }

    .link {
      color: $c-black;
      display: block;
      font-size: $f-small;
      font-weight: $fw-bold;
      line-height: $lh-xsmall;
      padding: .5rem;
      border-bottom: .125rem solid transparent;

      &:hover {
        background: transparent;
        color: $c-red;
      }
    }

    .icon-link {
      padding: 0;

      .icon {
        svg {
          path {
            fill: $c-black;
          }
        }
      }

      &:hover {
        background: transparent;

        .icon {
          svg {
            path {
              fill: $c-red;
            }
          }
        }
      }
    }

    &.active {
      .link {
        color: $c-red;
        border-bottom: .125rem solid $c-red;
      }
    }
  }
}