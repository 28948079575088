.filter {

  position: relative;
  z-index: 4;

  &__headline {

    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    @include breakpoint(medium) {
      font-size: 1.125rem;
    }

    @include breakpoint(xlarge) {
      line-height: 1.5rem;
    }
  }

  &__reset {

    display: none;
    align-items: center;
    justify-content: left;
    bottom: 0;
    z-index: 100;
    left: 0;
    margin-top: .5rem;
    border-bottom: .0625rem solid $c-black;

    color: $c-gray-60;
    font-size: $f-xsmall;
    line-height: $lh-xsmall;

    &.visible {
      display: inline-flex;
    }

    @include breakpoint(medium) {
      margin-top: 0;
      transition: border-color $t-animation-fast ease;
    }

    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
      border-color: transparent;

      .icon {
        svg {
          path {
            fill: $c-black;
          }
        }
      }
    }

    .text {
      margin-right: .5rem;
    }

    .icon {
      svg {
        path {
          fill: $c-gray-60;
          transition: fill $t-animation-fast ease;
        }
      }
    }
  }

  &-item {
    position: relative;

    &-label {
      cursor: pointer;
      background: $c-gray-5;
      position: relative;
      margin-bottom: .5rem;

      @include breakpoint(medium) {
        margin-bottom: 1rem;
      }

      .text {
        display: flex;
        height: 3.5rem;
        padding: 0 0 0 1rem;
        width: calc(100% - 3.5rem);
        justify-content: center;
        flex-direction: column;
        overflow: hidden;

        &-label {
          white-space: nowrap;
          font-size: $f-xsmall;
          line-height: $lh-xsmall;
          text-overflow: ellipsis;
          overflow: hidden;

          @include breakpoint(medium) {
            font-size: $f-small;
          }

          .selected & {
            font-weight: $fw-bold;
          }
        }

        .selected-values {
          white-space: nowrap;
          font-size: $f-xxsmall;
          line-height: $lh-xsmall;
          display: none;
          text-overflow: ellipsis;
          overflow: hidden;

          &.filled {
            display: block;
          }
        }
      }

      .icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        svg {
          path {
            fill: $c-black;
            transition: fill $t-animation-fast ease;
          }
        }

        &.filter-open {
          display: flex;
          transition: transform $t-animation-fast ease;

          .selected & {
            display: none;
          }

          .toggled & {
            transform: rotate(180deg);
          }
        }

        &.filter-remove {
          display: none;

          .selected & {
            display: flex;
          }

          &:hover {
            svg {
              path {
                fill: $c-red;
              }
            }
          }
        }
      }
    }

    &-values {
      opacity: 0;
      z-index: -1;
      position: absolute;
      pointer-events: none;
      top: 3.5rem;
      background: $c-gray-5;
      width: 100%;
      padding: 1rem;
      border-bottom: .125rem solid $c-gray-5;
      transition: opacity $t-animation-fast ease, z-index $t-animation-fast ease, border $t-animation-fast ease;

      .toggled & {
        opacity: 1;
        z-index: 2;
        pointer-events: auto;
        border-color: $c-black;
      }

      .item {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
