.ce-uploads {
	&__header {
		font-size: $f-small;
		line-height: $f-medium;
		margin-bottom: .5rem;

		@include breakpoint(medium) {
			font-size: $f-medium;
			line-height: $lh-small;
		}

		@include breakpoint(xlarge) {
			margin-bottom: 1rem;
		}

		.product__downloads &,
		.news--single & {
			font-size: $f-medium;
			line-height: $lh-small;
			margin-bottom: .5rem;

			@include breakpoint(medium) {
				font-size: $f-large;
				line-height: $lh-medium;
				margin-bottom: 1rem;
			}

			@include breakpoint(xlarge) {
				font-size: $f-xlarge;
				line-height: $lh-large;
				margin-bottom: 2rem;
			}
		}
	}

	&__item {
		margin-bottom: 1rem;
	}

	&__link {
		color: $c-black;
		display: flex;
		align-items: center;

		.icon {
			margin-right: 1.5rem;

			svg {
				path {
					fill: $c-red;
				}
			}
		}

		.filename {
			border-bottom: .0625rem solid $c-black;
			transition: border-color $t-animation-fast ease;
			font-size: $f-xsmall;
			line-height: $lh-xsmall;

			@include breakpoint(medium) {
				font-size: $f-small;
			}

			.filesize {
				text-transform: uppercase;
			}
		}

		&:visited,
		&:focus,
		&:active {
			.filename {
				text-decoration: none;
				// border-color: transparent; removed
			}
		}

		&:hover {
			.filename {
				text-decoration: none;
				border-color: transparent;
			}
		}
	}
}
