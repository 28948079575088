.nav-breadcrumb {
  padding: 2rem 0;

  @include breakpoint(xlarge) {
    padding: 2rem 0 3.5rem;
  }

  &__list {

    &__item {
      color: $c-gray-50;
      font-size: $f-xsmall;
      line-height: $lh-xsmall;
      display: inline-flex;
      align-items: center;

      @include breakpoint(xlarge) {
        font-size: $f-small;
      }

      &__anchor {
        color: $c-gray-50;
        font-size: $f-xsmall;
        line-height: $lh-xsmall;
        transition: color $t-animation-fast ease;

        @include breakpoint(xlarge) {
          font-size: $f-small;
        }

        &:hover {
          color: $c-black;
        }
      }

      .icon {
        margin: 0 .5rem;
        position: relative;
        top: -.0625rem;

        @include breakpoint(xlarge) {
          margin: 0 1rem;
        }

        svg {
          width: 1rem;
          height: 1rem;

          path {
            stroke: $c-gray-50;
          }
        }
      }
    }
  }
}