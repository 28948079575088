//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Flexbox Utilities
//  20. Forms
//  21. Label
//  22. Media Object
//  23. Menu
//  24. Meter
//  25. Off-canvas
//  26. Orbit
//  27. Pagination
//  28. Progress Bar
//  29. Prototype Arrow
//  30. Prototype Border-Box
//  31. Prototype Border-None
//  32. Prototype Bordered
//  33. Prototype Display
//  34. Prototype Font-Styling
//  35. Prototype List-Style-Type
//  36. Prototype Overflow
//  37. Prototype Position
//  38. Prototype Rounded
//  39. Prototype Separator
//  40. Prototype Shadow
//  41. Prototype Sizing
//  42. Prototype Spacing
//  43. Prototype Text-Decoration
//  44. Prototype Text-Transformation
//  45. Prototype Text-Utilities
//  46. Responsive Embed
//  47. Reveal
//  48. Slider
//  49. Switch
//  50. Table
//  51. Tabs
//  52. Thumbnail
//  53. Title Bar
//  54. Tooltip
//  55. Top Bar
//  56. Xy Grid
@import '../../../../../../../../../node_modules/foundation-sites/scss/util/util';

// 1. Global
// ---------
$global-font-size: 100%;
$global-width: rem-calc(1600);
$global-lineheight: 1;

// Colors
$c-red:     #ff0000;
$c-red-shade-1: #E20404;

$c-black:   #000000;
$c-white:   #ffffff;
$c-gray-85: #252525;
$c-gray-80: #333333;
$c-gray-70: #4d4d4d;
$c-gray-60: #666666;
$c-gray-50: #808080;
$c-gray-40: #999999;
$c-gray-30: #b3b3b3;
$c-gray-20: #cccccc;
$c-gray-10: #e6e6e6;
$c-gray-5:  #f2f2f2;
$c-green:   #8EBC47;
$c-mint:    #009090;

$foundation-palette: (
        primary: $c-red, // LAP red
        secondary: #002a3a, // LAP dark blue
        success: #00ff00, // LAP green
        warning: #ffae00,
        alert: $c-red, // LAP red
);

$body-background: $c-white;
$body-font-color: $c-black;
$body-font-family: 'FreeSans', Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: 400;
$global-weight-bold: 700;
$global-radius: 0;
$global-menu-padding: 0 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: pointer;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------
$breakpoints: (
        small: 0,
        medium: 768px,
        large: 1024px,
        xlarge: 1200px,
        xxlarge: 1440px,
        xxxlarge: 1680px,
        xxxxlarge: 1920px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge xxxlarge xxxxlarge);

// 3. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
        small: 16px,
        medium: 32px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

// 4. Base Typography
// ------------------
$header-font-family: $body-font-family;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-color: inherit;
$header-lineheight: 1.6;
$header-margin-bottom: 0;
$header-styles: (
        small: (
                'h1': ('font-size': 24),
                'h2': ('font-size': 20),
                'h3': ('font-size': 19),
                'h4': ('font-size': 18),
                'h5': ('font-size': 17),
                'h6': ('font-size': 16),
        ),
        medium: (
                'h1': ('font-size': 48),
                'h2': ('font-size': 40),
                'h3': ('font-size': 31),
                'h4': ('font-size': 25),
                'h5': ('font-size': 20),
                'h6': ('font-size': 16),
        ),
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $c-gray-50;
$paragraph-lineheight: 1.5;
$paragraph-margin-bottom: 2rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $c-black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $c-gray-30;
$code-border: 1px solid $c-gray-50;
$code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-color-hover: inherit;
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $c-gray-50;
$hr-margin: rem-calc(20) auto;
$list-lineheight: inherit;
$list-margin-bottom: 0;
$list-style-type: none;
$list-style-position: outside;
$list-side-margin: 0;
$list-nested-side-margin: 0;
$defnlist-margin-bottom: 0;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0;
$blockquote-color: $c-gray-80;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $c-gray-50;
$cite-font-size: rem-calc(13);
$cite-color: $c-gray-80;
$cite-pseudo-content: '\2014 \0020';
$keystroke-font: $font-family-monospace;
$keystroke-color: $c-black;
$keystroke-background: $c-gray-30;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $c-black;

// 5. Typography Helpers
// ---------------------
$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $c-gray-80;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;

// 6. Abide
// --------
$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: get-color(alert);
$form-label-color-invalid: get-color(alert);
$input-error-color: get-color(alert);
$input-error-font-size: rem-calc(12);
$input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------
$accordion-background: transparent;
$accordion-plusminus: false;
$accordion-title-font-size: inherit;
$accordion-item-color: inherit;
$accordion-item-background-hover: transparent;
$accordion-item-padding: 0;
$accordion-content-background: transparent;
$accordion-content-border: 0;
$accordion-content-color: inherit;
$accordion-content-padding: 0;

// 8. Accordion Menu
// -----------------

$accordionmenu-padding: $global-menu-padding;
$accordionmenu-nested-margin: $global-menu-nested-margin;
$accordionmenu-submenu-padding: $accordionmenu-padding;
$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $primary-color;
$accordionmenu-item-background: null;
$accordionmenu-border: null;
$accordionmenu-submenu-toggle-background: null;
$accordion-submenu-toggle-border: $accordionmenu-border;
$accordionmenu-submenu-toggle-width: 40px;
$accordionmenu-submenu-toggle-height: $accordionmenu-submenu-toggle-width;
$accordionmenu-arrow-size: 6px;


// 10. Breadcrumbs
// ---------------
$breadcrumbs-margin: $global-margin 0;
$breadcrumbs-item-font-size: inherit;
$breadcrumbs-item-color: $c-gray-40;
$breadcrumbs-item-color-current: $c-gray-40;
$breadcrumbs-item-color-disabled: $c-gray-40;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-separator: true;
$breadcrumbs-item-separator-item: '/';
$breadcrumbs-item-separator-item-rtl: '\\';
$breadcrumbs-item-separator-color: $c-gray-40;

// 11. Button
// ----------
$button-font-family: inherit;
$button-padding: inherit;
$button-margin: inherit;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: inherit;
$button-color: inherit;
$button-color-alt: inherit;
$button-radius: 0;
$button-hollow-border-width: 0;
$button-sizes: (
        tiny: 0.6rem,
        small: 0.75rem,
        default: 0.9rem,
        large: 1.25rem,
);
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: 0;
$button-hollow-hover-lightness: 0;
$button-transition: background-color 0.1s ease-out, color 0.1s ease-out;
$button-responsive-expanded: false;

// 12. Button Group
// ----------------
$buttongroup-margin: inherit;
$buttongroup-spacing: inherit;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;
$buttongroup-radius-on-each: false;

// 15. Close Button
// ----------------
$closebutton-position: right top;
$closebutton-offset-horizontal: (
        small: 0.66rem,
        medium: 1rem,
);
$closebutton-offset-vertical: (
        small: 0.33em,
        medium: 0.5rem,
);
$closebutton-size: (
        small: 1.5em,
        medium: 2em,
);
$closebutton-lineheight: 1;
$closebutton-color: $c-gray-80;
$closebutton-color-hover: $c-black;

// 16. Drilldown
// -------------
$drilldown-transition: transform 0.15s linear;
$drilldown-arrows: true;
$drilldown-padding: $global-menu-padding;
$drilldown-nested-margin: 0;
$drilldown-background: $c-white;
$drilldown-submenu-padding: $drilldown-padding;
$drilldown-submenu-background: $c-white;
$drilldown-arrow-color: $primary-color;
$drilldown-arrow-size: 6px;

// 17. Dropdown
// ------------
$dropdown-padding: 1rem;
$dropdown-background: $body-background;
$dropdown-border: 1px solid $c-gray-50;
$dropdown-font-size: 1rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (
        tiny: 100px,
        small: 200px,
        large: 400px,
);

// 18. Dropdown Menu
// -----------------
$dropdownmenu-arrows: true;
$dropdownmenu-arrow-color: $anchor-color;
$dropdownmenu-arrow-size: 6px;
$dropdownmenu-arrow-padding: 1.5rem;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: null;
$dropdownmenu-submenu-background: $c-white;
$dropdownmenu-padding: $global-menu-padding;
$dropdownmenu-nested-margin: 0;
$dropdownmenu-submenu-padding: $dropdownmenu-padding;
$dropdownmenu-border: 1px solid $c-gray-50;
$dropdown-menu-item-color-active: get-color(primary);
$dropdown-menu-item-background-active: transparent;

// 19. Flexbox Utilities
// ---------------------
$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;

// 20. Forms
// ---------
$fieldset-border: 1px solid $c-gray-50;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$helptext-color: $c-black;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;
$input-prefix-color: $c-black;
$input-prefix-background: $c-gray-30;
$input-prefix-border: 0;
$input-prefix-padding: 1rem;
$form-label-color: $c-black;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$select-background: $c-white;
$select-triangle-color: $c-gray-80;
$select-radius: $global-radius;
$input-color: $c-black;
$input-placeholder-color: $c-gray-50;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: $c-white;
$input-background-focus: $c-white;
$input-background-disabled: $c-gray-30;
$input-border: 0;
$input-border-focus: 0;
$input-padding: .5rem 1rem;
$input-shadow: none;
$input-shadow-focus: none;
$input-cursor-disabled: not-allowed;
$input-transition: none;
$input-number-spinners: true;
$input-radius: none;
$form-button-radius: none;

// 21. Label
// ---------
$label-background: transparent;
$label-color: $c-gray-50;
$label-color-alt: $c-black;
$label-palette: $foundation-palette;
$label-font-size: $f-xxsmall;
$label-padding: 0;
$label-radius: 0;

// 22. Media Object
// ----------------
$mediaobject-margin-bottom: $global-margin;
$mediaobject-section-padding: $global-padding;
$mediaobject-image-width-stacked: 100%;

// 23. Menu
// --------
$menu-margin: 0;
$menu-nested-margin: $global-menu-nested-margin;
$menu-items-padding: $global-menu-padding;
$menu-simple-margin: 1rem;
$menu-item-color-active: $c-white;
$menu-item-background-active: get-color(primary);
$menu-icon-spacing: 0.25rem;
$menu-state-back-compat: true;
$menu-centered-back-compat: true;
$menu-icons-back-compat: true;

// 24. Meter
// ---------
$meter-height: 1rem;
$meter-radius: $global-radius;
$meter-background: $c-gray-50;
$meter-fill-good: $success-color;
$meter-fill-medium: $warning-color;
$meter-fill-bad: $alert-color;


// 27. Pagination
// --------------
$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $c-black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $c-gray-30;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: $c-white;
$pagination-item-color-disabled: $c-gray-50;
$pagination-ellipsis-color: $c-black;
$pagination-mobile-items: false;
$pagination-mobile-current-item: false;
$pagination-arrows: true;

// 29. Prototype Arrow
// -------------------
$prototype-arrow-directions: (
        down,
        up,
        right,
        left
);
$prototype-arrow-size: 0.4375rem;
$prototype-arrow-color: $c-black;

// 30. Prototype Border-Box
// ------------------------
$prototype-border-box-breakpoints: $global-prototype-breakpoints;

// 31. Prototype Border-None
// -------------------------
$prototype-border-none-breakpoints: $global-prototype-breakpoints;

// 32. Prototype Bordered
// ----------------------
$prototype-bordered-breakpoints: $global-prototype-breakpoints;
$prototype-border-width: rem-calc(1);
$prototype-border-type: solid;
$prototype-border-color: $c-gray-50;

// 33. Prototype Display
// ---------------------
$prototype-display-breakpoints: $global-prototype-breakpoints;
$prototype-display: (
        inline,
        inline-block,
        block,
        table,
        table-cell
);

// 34. Prototype Font-Styling
// --------------------------
$prototype-font-breakpoints: $global-prototype-breakpoints;
$prototype-wide-letter-spacing: rem-calc(4);
$prototype-font-normal: $global-weight-normal;
$prototype-font-bold: $global-weight-bold;

// 35. Prototype List-Style-Type
// -----------------------------
$prototype-list-breakpoints: $global-prototype-breakpoints;
$prototype-style-type-unordered: (
        disc,
        circle,
        square
);
$prototype-style-type-ordered: (
        decimal,
        lower-alpha,
        lower-latin,
        lower-roman,
        upper-alpha,
        upper-latin,
        upper-roman
);

// 36. Prototype Overflow
// ---------------------
$prototype-overflow-breakpoints: $global-prototype-breakpoints;
$prototype-overflow: (
        visible,
        hidden,
        scroll
);

// 37. Prototype Position
// ----------------------
$prototype-position-breakpoints: $global-prototype-breakpoints;
$prototype-position: (
        static,
        relative,
        absolute,
        fixed
);
$prototype-position-z-index: 975;

// 38. Prototype Rounded
// ---------------------
$prototype-rounded-breakpoints: $global-prototype-breakpoints;
$prototype-border-radius: rem-calc(3);

// 39. Prototype Separator
// -----------------------
$prototype-separator-breakpoints: $global-prototype-breakpoints;
$prototype-separator-align: center;
$prototype-separator-height: rem-calc(2);
$prototype-separator-width: 3rem;
$prototype-separator-background: $primary-color;
$prototype-separator-margin-top: $global-margin;

// 40. Prototype Shadow
// --------------------
$prototype-shadow-breakpoints: $global-prototype-breakpoints;
$prototype-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16),
0 2px 10px 0 rgba(0, 0, 0, .12);

// 41. Prototype Sizing
// --------------------
$prototype-sizing-breakpoints: $global-prototype-breakpoints;
$prototype-sizing: (
        width,
        height
);
$prototype-sizes: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%
);

// 42. Prototype Spacing
// ---------------------
$prototype-spacing-breakpoints: $global-prototype-breakpoints;
$prototype-spacers-count: 3;

// 43. Prototype Text-Decoration
// -----------------------------
$prototype-decoration-breakpoints: $global-prototype-breakpoints;
$prototype-text-decoration: (
        overline,
        underline,
        line-through,
);

// 44. Prototype Text-Transformation
// ---------------------------------
$prototype-transformation-breakpoints: $global-prototype-breakpoints;
$prototype-text-transformation: (
        lowercase,
        uppercase,
        capitalize
);

// 45. Prototype Text-Utilities
// ----------------------------
$prototype-utilities-breakpoints: $global-prototype-breakpoints;
$prototype-text-overflow: ellipsis;

// 46. Responsive Embed
// --------------------
$responsive-embed-margin-bottom: 0;
$responsive-embed-ratios: (
        default: 4 by 3,
        widescreen: 16 by 9,
);

// 47. Reveal
// ----------
$reveal-background:  rgba($c-black, .8);
$reveal-width: $global-width;
$reveal-max-width: $global-width;
$reveal-padding: 0;
$reveal-border: 0;
$reveal-radius: 0;
$reveal-zindex: 1005;
$reveal-overlay-background: rgba($c-black, .8);

// 50. Table
// ---------
$table-background: $c-white;
$table-color-scale: 5%;
$table-border: 0;
$table-padding: 1rem;
$table-hover-scale: 2%;
$table-row-hover: darken($table-background, $table-hover-scale);
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
$table-is-striped: true;
$table-striped-background: $c-gray-30;
$table-stripe: odd;
$table-head-background: $c-white;
$table-head-row-hover: darken($table-head-background, $table-hover-scale);
$table-foot-background: smart-scale($table-background, $table-color-scale);
$table-foot-row-hover: darken($table-foot-background, $table-hover-scale);
$table-head-font-color: $body-font-color;
$table-foot-font-color: $body-font-color;
$show-header-for-stacked: false;
$table-stack-breakpoint: medium;

// 51. Tabs
// --------
$tab-margin: 0;
$tab-background: $c-white;
$tab-color: $primary-color;
$tab-background-active: $c-gray-30;
$tab-active-color: $primary-color;
$tab-item-font-size: rem-calc(12);
$tab-item-background-hover: $c-white;
$tab-item-padding: 1.25rem 1.5rem;
$tab-content-background: $c-white;
$tab-content-border: $c-gray-30;
$tab-content-color: $body-font-color;
$tab-content-padding: 1rem;

// 52. Thumbnail
// -------------
$thumbnail-border: 4px solid $c-white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($c-black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

// 53. Title Bar
// -------------
$titlebar-background: $c-black;
$titlebar-color: $c-white;
$titlebar-padding: 0.5rem;
$titlebar-text-font-weight: bold;
$titlebar-icon-color: $c-white;
$titlebar-icon-color-hover: $c-gray-50;
$titlebar-icon-spacing: 0.25rem;

// 54. Tooltip
// -----------
$has-tip-cursor: help;
$has-tip-font-weight: $global-weight-bold;
$has-tip-border-bottom: dotted 1px $c-gray-80;
$tooltip-background-color: $c-black;
$tooltip-color: $c-white;
$tooltip-padding: 0.75rem;
$tooltip-max-width: 10rem;
$tooltip-font-size: $small-font-size;
$tooltip-pip-width: 0.75rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-radius: $global-radius;

// 55. Top Bar
// -----------
$topbar-padding: 0;
$topbar-background: $c-white;
$topbar-submenu-background: $topbar-background;
$topbar-title-spacing: 0.5rem 1rem 0.5rem 0;
$topbar-input-width: 200px;
$topbar-unstack-breakpoint: medium;

// 56. Xy Grid
// -----------
$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
        small: 16px,
        medium: 32px
);

$grid-container-gutters: (
        small: 40px,
        large: 96px
);

$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-container-gutters;
$grid-container-max: $global-width;
$xy-block-grid-max: 8;

.grid-x {
        &.no-wrap {
                flex-flow: nowrap;
        }
}

// Shadows & Gradients
$header-shadow: 0 0 .5rem rgba($c-black, .05), 0 .75rem 1rem rgba($c-black, .05);
$slider-overlay: linear-gradient(360deg, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, 0) 100%);
$dot-nav-shadow: 0 0 .5rem rgba(0, 0, 0, .08), 0 .25rem .5rem rgba(0, 0, 0, .08);
$tab-nav-overlay-right: linear-gradient(to right, rgba($c-white, 0) 0%, rgba($c-white, .06) 8%, rgba($c-white, .22) 16%, rgba($c-white, .74) 28%, rgba($c-white, .94) 55%, $c-white 74%, $c-white 100%);
$teaserbox-hover: rgba($c-red, .5);
$cookiemessage-bg: rgba($c-black, .5);

// Slick Slider
$slider-item-height-small: 27rem;
$slider-item-height-medium: 38rem;
$slider-item-height-xlarge: 58.5rem;

$slider-nav-height-small: 3rem;
$slider-nav-height-medium: 4rem;
$slider-nav-height-xlarge: 5rem;

// Animation Timings
$t-animation-fast: .2s;
