::placeholder {
	color: $c-gray-20;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $c-gray-20;
}

::-ms-input-placeholder {
	color: $c-gray-20;
}

::-ms-clear {
	display: none;
}

.form {

	&--search {
		position: relative;

		::placeholder {
			color: $c-black;
		}

		:-ms-input-placeholder {
			color: $c-black;
		}

		::-ms-input-placeholder {
			color: $c-black;
		}

		&--mobile {
			::placeholder {
				color: $c-gray-50;
			}

			:-ms-input-placeholder {
				color: $c-gray-50;
			}

			::-ms-input-placeholder {
				color: $c-gray-50;
			}
		}
	}

	.hint {
		color: $c-gray-20;
		margin-top: 1rem;
		font-size: $f-xsmall;
		line-height: $lh-xsmall;

		@include breakpoint(xlarge) {
			font-size: $f-small;
		}
	}

	&__error-message {
		display: none;
	}

	&__fieldset {
		margin-bottom: 2rem;

		&__title {
			font-size: $f-small;
			line-height: $lh-xsmall;
			margin-bottom: 1rem;

			@include breakpoint(medium) {
				font-size: $f-medium;
				line-height: $lh-small;
			}
		}
	}

	&__field {
		position: relative;
		margin-bottom: 1rem;

		@include breakpoint(xlarge) {
			margin-bottom: 2rem;
		}

        p {
            font-size: $f-xsmall;
            line-height: $lh-xsmall;

            @include breakpoint(medium) {
                font-size: $f-small;
            }
        }
	}

	&__button-group {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		@include breakpoint(medium) {
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
		}

		.item {
			margin-bottom: 1rem;

			@include breakpoint(medium) {
				margin-bottom: 0;
			}

			&--login {
				order: 1;

				@include breakpoint(medium) {
					order: 3;
				}
			}

			&--register {
				order: 2;
			}

			&--forgot-pw {
				margin-top: 1rem;
				order: 3;

				@include breakpoint(medium) {
					margin-top: 0;
					order: 1;
				}
			}

			&--send-pw {
				order: 1;

				@include breakpoint(medium) {
					order: 2;
				}
			}

			&--back {
				margin-top: 1rem;
				order: 2;

				@include breakpoint(medium) {
					margin-top: 0;
					order: 1;
				}
			}

			@include breakpoint(medium) {
				margin: 0;
			}
		}
	}
}

.search-button {
	position: absolute;
	right: 0;
	top: .625rem;
	width: 1.5rem;
	height: 1.5rem;
	outline: 0;

	.icon {
		svg {
			path {
				fill: $c-black;
				transition: fill $t-animation-fast ease;

				.search__overlay & {
					fill: $c-gray-50;
				}
			}
		}
	}

	&.has-hover {
		&:hover {
			.icon {
				svg {
					path {
						fill: $c-red;
					}
				}
			}
		}
	}
}

.label {
	line-height: $lh-xxsmall;
	font-size: $f-xxsmall;
	color: $c-gray-20;
	margin-top: .5rem;
	display: block;
}

.checkbox {
	position: absolute;
	opacity: 0;
	left: -9999rem;

	+ label {
		display: block;
		position: relative;
		cursor: pointer;
		padding-left: 2.5rem;
		font-size: $f-xsmall;
		line-height: $lh-xsmall;

		@include breakpoint(medium) {
			font-size: $f-small;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			display: inline-block;
			width: 1.5rem;
			height: 1.5rem;
			background: $c-gray-10;
			border: .125rem solid $c-gray-10;
			transition: border $t-animation-fast ease, background $t-animation-fast ease;

			.filter & {
				background: $c-white;
				border-color: $c-white;
			}

			.has-error & {
				border-color: $c-red;
			}
		}
	}

	&.f3-form-error {
		+ label {
			&:after {
				border-color: $c-red;
			}
		}

		&:hover {
			+ label {
				&:after {
					border-color: $c-red;
				}
			}
		}
	}

	&:hover + label {
		&:after {
			border-color: $c-gray-10;

			.filter & {
				border-color: $c-white;
			}

			.has-error & {
				border-color: $c-red;
			}
		}
	}

	&:checked + label {
		&:before {
			content: '';
			background: url('data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10.8167%202.10184C11.0304%202.27673%2011.0619%202.59174%2010.887%202.80545L5.28655%209.64883C4.8783%2010.1477%204.11166%2010.1357%203.71927%209.62422L1.10333%206.21458C0.935238%205.99549%200.976581%205.68162%201.19567%205.51353C1.41476%205.34544%201.72863%205.38678%201.89672%205.60587L4.51267%209.01551L10.1131%202.17212C10.288%201.95842%2010.603%201.92695%2010.8167%202.10184Z%22%20fill%3D%22%23FF0000%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat center center;
			position: absolute;
			top: .375rem;
			left: .375rem;
			width: .75rem;
			height: .75rem;
			z-index: 1;
		}
	}

	&--disabled {
		+ label {
			cursor: not-allowed;
			opacity: .4;
		}
	}

}

.input,
.select,
.textarea {
	// sass-lint:disable no-important
	font-size: $f-xsmall !important;
	color: $c-gray-20;
	width: 100%;
	outline: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-border-radius: 0;
	border: 0;
	transition: color $t-animation-fast ease, border-color $t-animation-fast ease;
	padding: 0;

	@include breakpoint(medium) {
		font-size: $f-small !important;
	}
	// sass-lint:enable no-important

	.has-error &,
	&.f3-form-error {
		color: $c-red;
		border-color: $c-red;

		&::placeholder {
			color: $c-red;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: $c-red;
		}

		&::-ms-input-placeholder {
			color: $c-red;
		}
	}

	&:-webkit-autofill {
		box-shadow: inset 0 0 0 9999rem $c-white;
		font-size: inherit;

		.has-error &,
		&.f3-form-error {
			-webkit-text-fill-color: $c-red;
		}
	}

	+ .label {
		opacity: 0;
		transition: opacity $t-animation-fast ease;
	}

	&:focus {
		color: $c-black;
		border-color: $c-black;

		.has-error &,
		&.f3-form-error {
			color: $c-red;
			border-color: $c-red;
		}

		&:-webkit-autofill {
			box-shadow: inset 0 0 0 9999rem $c-white;

			.has-error &,
			&.f3-form-error {
				-webkit-text-fill-color: $c-red;
			}
		}

		+ .label {
			color: $c-black;
			opacity: 1;

			.has-error &,
			&.f3-form-error {
				color: $c-red;
			}

		}
	}

	&.filled {
		color: $c-black;

		.has-error &,
		&.f3-form-error {
			color: $c-red;
		}

		+ .label {
			opacity: 1;

			.has-error &,
			&.f3-form-error {
				color: $c-red;
			}
		}
	}
}

.input,
.select {
	height: 3rem;
	line-height: 3rem;
	border-bottom: .125rem solid $c-gray-20;

	&--search {
		border-bottom-color: $c-black;
		padding-right: 2.5rem;

		.search__overlay & {
			border-color: $c-gray-50;
		}
	}
}

.textarea {
	border-bottom: .125rem solid $c-gray-20;
	height: 3rem;
	line-height: 3rem;
	transition: height $t-animation-fast ease, line-height $t-animation-fast ease;
	overflow: hidden;
	min-height: 3rem;
	resize: none;

	&:focus,
	&.filled {
		line-height: $lh-xsmall;
		height: 14rem;
		overflow: auto;
	}
}

.select {
	cursor: pointer;
	background: transparent;
	padding-right: 2rem;

	&::-ms-clear,
	&::-ms-expand {
		display: none;
	}
}

.select-wrap {
	position: relative;

	&:before {
		content: '';
		background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10.5854%2021.3905C11.3665%2022.1716%2012.6328%2022.1716%2013.4139%2021.3905L19.7071%2015.0973C20.0976%2014.7068%2020.0976%2014.0736%2019.7071%2013.6831C19.3166%2013.2925%2018.6834%2013.2925%2018.2929%2013.6831L12.9996%2018.9763L12.9996%202.99991C12.9996%202.44762%2012.5519%201.99991%2011.9996%201.99991C11.4474%201.99991%2010.9996%202.44762%2010.9996%202.99991L10.9996%2018.9763L5.70639%2013.6831C5.31587%2013.2925%204.6827%2013.2925%204.29218%2013.6831C3.90165%2014.0736%203.90165%2014.7068%204.29218%2015.0973L10.5854%2021.3905Z%22%20fill%3D%22%23FF0000%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat center center;
		pointer-events: none;
		width: 1.5rem;
		height: 3rem;
		position: absolute;
		right: 0;
		line-height: 3rem;
		z-index: 1;
	}
}

.input-wrap {
	position: relative;
}

.label-link {
	&:focus,
	&:hover {
		color: $c-red;
		text-decoration: none;
		border-color: $c-red;
		outline: 0;
	}

	&:visited,
	&:active {
		color: $c-red;
	}
}

/* Login */
.tx-felogin-pi1 {
	h3 {
		@extend .form__fieldset__title;
	}
}

.formerrors {
	background: $c-red;
	color: $c-white;
	font-size: $f-xsmall;
	line-height: $lh-xsmall;
	padding: .5rem;
	margin-bottom: 2rem;

	@include breakpoint(medium) {
		font-size: $f-small;
		line-height: $lh-small;
		padding: 1rem;
		margin-bottom: 4rem;
	}
}

/* Show password */
.icon--show-password {
	cursor: pointer;
	position: absolute;
	top: .75rem;
	right: 0;

	path {
		fill: $c-gray-20;
		transition: fill $t-animation-fast ease;
	}
}

.input--password {
	padding-right: 2rem;

	&:focus,
	&.filled {
		~ .icon--show-password {
			path {
				fill: $c-black;
			}
		}
	}

	&.f3-form-error {
		~ .icon--show-password {
			path {
				fill: $c-red;
			}
		}
	}
}
