.ce-table {
  &__title {
    font-size: $f-medium;
    line-height: $lh-small;
    margin-bottom: .5rem;

    @include breakpoint(medium) {
      font-size: $f-large;
      line-height: $lh-medium;
      margin-bottom: 1rem;
    }

    @include breakpoint(xlarge) {
      font-size: $f-xlarge;
      line-height: $lh-large;
      margin-bottom: 2rem;
    }
  }

  &--scrollable {
    width: 100%;
    overflow: hidden;
  }

  &__table {
    table-layout: fixed;

    &__tr {
      &:nth-child(odd) {
        background: $c-gray-5;
      }
    }

    &__th,
    &__td {
      padding: 1rem;
      font-size: $f-small;
      line-height: $lh-xsmall;
      word-break: break-word;
      //white-space: nowrap;
      text-align: left;
      vertical-align: top;
    }

    &__th {
      color: $c-black;
      border-right: .125rem solid $c-white;
    }

    &__td {
      color: $c-gray-50;
    }
  }
}
