// sass-lint:disable no-important
::selection {
  background: $c-red;
  color: $c-white;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  overflow: hidden;
}

// Helper classes
.margin-bottom-4 {
  margin-bottom: 4rem !important;
}

.margin-bottom-5 {
  margin-bottom: 5rem !important;
}

.margin-bottom-6 {
  margin-bottom: 6rem !important;
}

.margin-top-n1 {
  margin-top: -1rem !important;
}

.margin-top-n2 {
  margin-top: -2rem !important;
}

.margin-top-n3 {
  margin-top: -3rem !important;
}

.margin-top-n4 {
  margin-top: -4rem !important;
}

.margin-top-n5 {
  margin-top: -5rem !important;
}

.margin-bottom-n1 {
  margin-bottom: -1rem !important;
}

.margin-bottom-n2 {
  margin-bottom: -2rem !important;
}

.margin-bottom-n3 {
  margin-bottom: -3rem !important;
}

.margin-bottom-n4 {
  margin-bottom: -4rem !important;
}

.margin-bottom-n5 {
  margin-bottom: -5rem !important;
}

@include breakpoint(medium up) {
  .margin-top-medium-n1 {

    margin-top: -1rem !important;
  }

  .margin-top-medium-n2 {

    margin-top: -2rem !important;
  }

  .margin-top-medium-n3 {

    margin-top: -3rem !important;
  }

  .margin-top-medium-n4 {

    margin-top: -4rem !important;
  }

  .margin-top-medium-n5 {

    margin-top: -5rem !important;
  }

  .margin-bottom-medium-n1 {

    margin-bottom: -1rem !important;
  }

  .margin-bottom-medium-n2 {

    margin-bottom: -2rem !important;
  }

  .margin-bottom-medium-n3 {

    margin-bottom: -3rem !important;
  }

  .margin-bottom-medium-n4 {

    margin-bottom: -4rem !important;
  }

  .margin-bottom-medium-n5 {

    margin-bottom: -5rem !important;
  }
}

@include breakpoint(large up) {
  .margin-top-large-n1 {

    margin-top: -1rem !important;
  }

  .margin-top-large-n2 {

    margin-top: -2rem !important;
  }

  .margin-top-large-n3 {

    margin-top: -3rem !important;
  }

  .margin-top-large-n4 {

    margin-top: -4rem !important;
  }

  .margin-top-large-n5 {

    margin-top: -5rem !important;
  }

  .margin-bottom-large-n1 {

    margin-bottom: -1rem !important;
  }

  .margin-bottom-large-n2 {

    margin-bottom: -2rem !important;
  }

  .margin-bottom-large-n3 {

    margin-bottom: -3rem !important;
  }

  .margin-bottom-large-n4 {

    margin-bottom: -4rem !important;
  }

  .margin-bottom-large-n5 {

    margin-bottom: -5rem !important;
  }
}
// sass-lint:enable no-important
