// Font Sizes
$f-xxxsmall:   .75rem; // 12px
$f-xxsmall:   .875rem; // 14px
$f-xsmall:       1rem; // 16px
$f-small:    1.125rem; // 18px
$f-medium:     1.5rem; // 24px
$f-large:     2.25rem; // 36px
$f-xlarge:       3rem; // 48px
$f-xxlarge:      4rem; // 64px
$f-xxxlarge:  4.75rem; // 76px

// Line Heights
$lh-xxsmall:     1rem; // 16px
$lh-xsmall:    1.5rem; // 24px
$lh-small:       2rem; // 32px
$lh-medium:      3rem; // 48px
$lh-large:       4rem; // 64px
$lh-xlarge:      5rem; // 80px

// Font Weights
$fw-normal: 400;
$fw-bold:   700;

.hyphens {
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    overflow-wrap: break-word;
}
