.nav-siteswitch {
	&__heading {
		font-size: $f-xxxsmall;
		color: $c-gray-50;
		line-height: $lh-small;
	}

	&__list {
		display: flex;
		flex-direction: column;

		@include breakpoint(large) {
			flex-direction: row;
		}

		&__item {
			&__anchor {
				display: inline-flex;
				width: 100%;
				padding: 0;
				align-items: center;
				font-size: $f-xxsmall;
				line-height: $lh-small;
				letter-spacing: 1px;
				color: $c-gray-50;
				transition: color $t-animation-fast ease;

				@include breakpoint(large) {
					height: 4rem;
					padding: 0 1.5rem;
					line-height: $lh-xsmall;
				}

				@include breakpoint(xxlarge) {
					padding: 0 2rem;
				}

				&:hover,
				&:focus,
				&:active {
					color: $c-black;
				}

				&.active {
					color: $c-red;

					@include breakpoint(large) {
						padding-bottom: .25rem;
						border-top: .25rem solid $c-red;
						background: $c-white;
						color: $c-black;
					}
				}
			}
		}
	}
}
