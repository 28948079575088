.tabs {

  .tab-nav {
    margin-bottom: .5rem;

    @include breakpoint(medium) {
      margin-bottom: 1rem;
    }

    @include breakpoint(xlarge) {
      margin-bottom: 1.5rem;
    }

    //&__inner {
    //  position: relative;
    //
    //  &:after {
    //    content: '';
    //    display: block;
    //    position: absolute;
    //    right: -20px;
    //    top: 0;
    //    height: 100%;
    //    width: 50px;
    //    background: $tab-nav-overlay-right;
    //    z-index: 5;
    //  }
    //}
  }

  .tab-content {

    &.is-hidden {
      display: none;
    }

    &.is-active {
      display: block;
    }

    &__title {
      font-size: $f-small;
      line-height: $lh-xsmall;
      margin-bottom: 1.5rem;

      @include breakpoint(medium) {
        font-size: $f-medium;
        line-height: $lh-small;
      }
    }

    &__text {
      font-size: $f-xsmall;
      line-height: $lh-xsmall;
      margin-bottom: 1.5rem;

      @include breakpoint(medium) {
        font-size: $f-small;
        margin-bottom: 2rem;
      }

      @include breakpoint(xlarge) {
        margin-bottom: 2.5rem;
      }
    }
  }
}
