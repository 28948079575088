.icon {
  line-height: 0;

  svg {
    height: 1.5rem;
    width: 1.5rem;

    path {
      transition: fill $t-animation-fast ease;
    }
  }

  &--small {
    svg {
      height: 1rem;
      width: 1rem;
    }
  }
}