.ce-awards {
  &__section {
    &__title {
      font-size: $f-medium;
      line-height: $lh-small;
      margin-bottom: .5rem;

      @include breakpoint(medium) {
        font-size: $f-large;
        line-height: $lh-medium;
        margin-bottom: 1rem;
      }

      @include breakpoint(xlarge) {
        font-size: $f-xlarge;
        line-height: $lh-large;
        margin-bottom: 2rem;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    &__item {
      margin-top: .5rem;

      &:first-child {
        margin-top: 0;
      }

      @include breakpoint(medium) {
        margin-top: 0;
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }

      &__link {
        display: block;
      }

      &__image {
        max-width: 352px;
      }
    }
  }
}
