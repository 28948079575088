.facet-link {
    $self: &;
    height: 3.5rem;
    line-height: 1.3;
    font-size: $f-small;
    font-weight: $fw-bold;
    border-bottom: .25rem solid $c-gray-20;
    color: $c-gray-20;
    display: inline-flex;
    align-items: center;
    transition: color $t-animation-fast ease, border $t-animation-fast ease;
    outline: 0;

    &__text {
        margin-right: 1rem;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
        color: $c-black;
        border-color: $c-black;

        .icon {
            svg {
                path {
                    fill: $c-black;
                }
            }
        }
    }
}
