.news {

  // List
  &__list {
    &__item {
      $self: &;

      .latest & {
        &:nth-child(2),
        &:nth-child(3) {
          display: none;

          @include breakpoint(medium) {
            display: block;
          }
        }
      }

      // Image
      &__image {
        &-wrap {
          margin-bottom: 1rem;

          @include breakpoint(xlarge) {
            margin-bottom: 2rem;
          }
        }
      }

      // Date
      &__date {
        color: $c-gray-50;
        font-size: $f-xsmall;
        line-height: $lh-xsmall;

        @include breakpoint(xlarge) {
          margin-bottom: .5rem;
        }
      }

      // Title
      &__title {
        font-size: $f-small;
        line-height: $lh-xsmall;
        margin-bottom: 1rem;
        color: $c-black;
        transition: color $t-animation-fast ease;

        @include breakpoint(xlarge) {
          font-size: $f-medium;
          line-height: $lh-small;
          margin-bottom: .5rem;
        }

      }

      // Text
      &__text {
        font-size: $f-xsmall;
        line-height: $lh-xsmall;
        margin-bottom: 1rem;
        color: $c-black;

        @include breakpoint(xlarge) {
          font-size: $f-small;
          line-height: $lh-small;
          margin-bottom: 2.5rem;
        }

        &.no-margin {
          margin-bottom: 0;
        }
      }

      &__anchor {
        display: block;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        @include breakpoint(xlarge) {
          padding-bottom: 2rem;
          margin-bottom: 2rem;
        }

        // Item Hover States
        &:focus,
        &:hover {
          outline: 0;

          #{ $self }__title {
            color: $c-red;
            outline: 0;
          }
        }
      }
    }

    &__button-wrap {
      display: flex;
      justify-content: left;

      @include breakpoint(medium) {
        justify-content: center;
      }
    }
  }

  // Detail
  &__detail {
    &__media {
      margin-bottom: 2rem;
    }
  }

  //Banner
  &__banner {
    background-color: $c-gray-5;
    max-height: 0;

    transform: translateY(-150px);
    transition: transform .3s ease, max-height .3s ease;

    @include breakpoint(medium) {
      transform: translateY(-130px);
    }

    &.active {
      transform: translateY(0);
      max-height: 200px;
    }

    &__image-wrap {
      color: $c-black;
      flex-shrink: 0;
      text-align: right;
      overflow: hidden;
      width: 136px;
      height: 68px;

      @include breakpoint(medium) {
        width: 224px;
        height: 110px;
      }
    }

    &__text-wrap {
      margin-left: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: $c-black;

      @include breakpoint(medium) {
        margin-left: 32px;
      }
    }

    &__image {
      width: auto;
      height: 100%;
    }

    &__top-news,
    &__headline {
      color: $c-red;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;

      @include breakpoint(medium) {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
      }
    }

    &__headline {
      color: $c-black;

      .news__banner__button {
        display: inline;
        line-height: 16px;
      }

      @include breakpoint(medium) {

        .news__banner__button {
          display: none;
        }

        .no-teaser {
          display: inline;
          line-height: 24px;
        }
      }
    }

    &__teaser {
      display: none;

      .news__banner__button {
        display: none;
      }

      @include breakpoint(medium) {
        display: inline;
        color: $c-black;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-right: 3.5rem;

        .news__banner__button {
          display: inline;
          line-height: 24px;
        }
      }
    }

    &__button {
      position: relative;
      top: 0;
      left: 0;
      display: inline;
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-left: .375rem;
      padding-right: 1.25rem;

      @include breakpoint(medium) {
        margin-left: 1.25rem;
      }

      .icon {
        position: absolute;
        left: 0;
        transition: left .2s ease;

        svg {
          width: 16px;
          height: 16px;
        }

        @include breakpoint(medium) {
          transform: translateY(-2px);

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      padding: 16px 0;
      margin: 0 -1rem 0 0;

      @include breakpoint(medium) {
        margin: 0;
      }

      &:hover {
        .news__banner__button {

          .icon {
            left: 0;

            @include breakpoint(medium) {
              left: -1rem;
            }
          }
        }
      }
    }

    &__close {
      position: absolute;
      top: .75rem;
      right: .75rem;
      width: .75rem;
      height: .75rem;
      cursor: pointer;
      transform: translate(-50%, -50%);

      @include breakpoint(medium) {
        top: 1.5rem;
        right: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }

      &:hover {
        svg {
          path {
            fill: $c-black;
          }
        }
      }
    }
  }

  .page-navigation {
    ul {
      float: none;
    }
  }
}
