.tt-address {
  &__list {
    &__item {
      margin-top: 2rem;

      &:first-child {
        margin-top: 0;
      }

      @include breakpoint(medium) {
        margin-top: 3rem;

        &:first-child, &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &-wrap {
          margin-bottom: 1rem;

          width: 10rem;
          height: 10rem;
        }
      }

      .title-position-wrap {
        margin-bottom: 1rem;

        @include breakpoint(xlarge) {
          margin-bottom: 1.5rem;
        }
      }

      .title {
        font-size: $f-small;
        line-height: $lh-xsmall;

        @include breakpoint(xlarge) {
          font-size: $f-medium;
          line-height: $lh-small;
        }
      }

      .position {
        color: $c-gray-50;
        font-size: $f-xsmall;
        line-height: $lh-xsmall;

        @include breakpoint(xlarge) {
          font-size: $f-small;
        }
      }

      .description {
        color: $c-black;
        line-height: $lh-xsmall;
        font-size: $f-xsmall;
        margin-bottom: 1rem;

        @include breakpoint(xlarge) {
          margin-bottom: 1.5rem;
          font-size: $f-small;
        }

        p,
        ul {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .contact-data {
        color: $c-black;
        line-height: $lh-xsmall;
        font-size: $f-xsmall;

        @include breakpoint(xlarge) {
          font-size: $f-small;
        }

        .phone {
          &__link {
            text-decoration: none;
            color: $c-black;
            cursor: default;

            &:hover,
            &:active,
            &:visited {
              color: $c-black;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
