.ce-text-image {
  &__title {
    font-size: $f-medium;
    line-height: $lh-small;
    margin-bottom: .5rem;

    @include breakpoint(medium) {
      font-size: $f-large;
      line-height: $lh-medium;
      margin-bottom: 1rem;
    }

    @include breakpoint(xlarge) {
      font-size: $f-xlarge;
      line-height: $lh-large;
      margin-bottom: 2rem;
    }

    .page-intro & {
      margin-bottom: 0;
      font-size: $f-large;
      line-height: $lh-medium;

      @include breakpoint(medium) {
        font-size: $f-xxlarge;
        line-height: $lh-xlarge;
      }
    }
  }

  &__text {
    font-size: $f-xsmall;
    line-height: $lh-xsmall;
    margin-bottom: .5rem;

    @include breakpoint(medium) {
      font-size: $f-small;
      line-height: $lh-small;
      margin-bottom: 1rem;
    }

    @include breakpoint(xlarge) {
      margin-bottom: 2rem;
    }

    .page-intro & {
      margin-bottom: 0;
      margin-top: .5rem;

      @include breakpoint(medium) {
        margin-top: 1rem;
        line-height: $lh-xsmall;
      }

      @include breakpoint(xlarge) {
        margin-top: 2rem;
        font-size: $f-medium;
        line-height: $lh-small;
      }
    }
  }

  &__image {
    &-wrap {
      width: calc(100% + 2.5rem);
      position: relative;
      left: -1.25rem;
      margin-bottom: 1rem;

      @include breakpoint(medium) {
        width: 100%;
        left: 0;
        position: static;
        margin-bottom: 0;
      }
    }
  }

  // Highlighttext (gray background)
  &--highlight {
    background: $c-gray-5;
    padding: 2.5rem 0;

    @include breakpoint(medium) {
      padding: 5rem 0;
    }

    @include breakpoint(xlarge) {
      padding: 6rem 0;
    }
  }
}
