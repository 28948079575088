.button {
    height: 3.5rem;
    border: 0;
    border-radius: .0625rem 6.25rem 6.25rem .0625rem;
    background-color: $c-red;
    line-height: $lh-xsmall;
    font-size: $f-small;
    font-weight: $fw-bold;
    display: inline-flex;
    gap: 1rem;
    padding: .75rem 1.375rem;
    align-items: center;
    transition: background-color $t-animation-fast ease, gap $t-animation-fast ease, padding $t-animation-fast ease;
    outline: 0;

    &__text {
        color: $c-white;
    }

    &__icon {
        svg {
            path {
                fill: $c-white;
            }
        }
    }

  &:hover {
      gap: 18px;
      padding: .75rem 1.25rem .75rem 1.375rem;
      background-color: $c-red-shade-1;
  }

    &:active {
        padding: .875rem 1.25rem .625rem 1.375rem;
    }

  &--icon-left {
        border-radius: 6.25rem .0625rem .0625rem 6.25rem;

        &:hover {
          padding: .75rem 1.375rem .75rem 1.25rem;
        }

        &:active {
            padding: .875rem 1.375rem .625rem 1.25rem;
        }
  }

  &--rte {
    color: $c-white;
    padding-right: 3.875rem;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        right: 1.375rem;
        top: 50%;
        margin-top: 0;
        transform: translateY(-50%);
        background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M21.3907%2013.4146C22.1717%2012.6335%2022.1717%2011.3672%2021.3907%2010.5861L15.0974%204.29289C14.7069%203.90237%2014.0737%203.90237%2013.6832%204.29289C13.2927%204.68342%2013.2927%205.31658%2013.6832%205.70711L18.9764%2011.0004L3.00003%2011.0004C2.44774%2011.0004%202.00003%2011.4481%202.00003%2012.0004C2.00003%2012.5526%202.44774%2013.0004%203.00003%2013.0004L18.9764%2013.0004L13.6832%2018.2936C13.2927%2018.6841%2013.2927%2019.3173%2013.6832%2019.7078C14.0737%2020.0983%2014.7069%2020.0983%2015.0974%2019.7078L21.3907%2013.4146Z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat center;
        transition: right $t-animation-fast ease, margin-top $t-animation-fast ease;
    }

      &:focus {
          color: $c-white;
      }

      &:hover,
      &:active {
          color: $c-white;
          padding-right: 3.875rem;

          &:after {
              right: 1.25rem;
          }
      }

      &:active {
          &:after {
              margin-top: .125rem;
              right: 1.25rem;
          }
      }
  }

}
