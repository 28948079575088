.nav-main {
  left: -100vw;
  transition: left $t-animation-fast ease-in-out;
  position: fixed;
  height: calc(100vh - 72px);
  width: auto;
  z-index: -1;

  .nav-close-layer {
    content: '';
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }

  @include breakpoint(large) {
    z-index: 1;
    left: -25vw;
    height: calc(100vh - 96px);
  }

  @include breakpoint(xxlarge) {
    left: -20vw;
  }

  &.is-open {
    left: 0;

    .nav-close-layer {
      display: block;
    }
  }

  &__col {
    background: $c-white;
    padding: 1.5rem;
    width: 100vw;
    transition: left $t-animation-fast ease-in-out, opacity $t-animation-fast ease-in-out;
    left: -100vw;
    opacity: 0;
    height: 100%;
    position: absolute;
    z-index: 1;
    overflow: auto;
    pointer-events: none;
    color: $c-black;

    @include breakpoint(large) {
      border-right: .125rem solid $c-gray-5;
      width: 25vw;
      left: -100%;
    }

    @include breakpoint(xxlarge) {
      padding: 2rem;
      width: 20vw;
    }

    &.is-open {
      left: 0;
      opacity: 1;
      pointer-events: auto;
      transition: left $t-animation-fast ease-in-out;

      &.level-02 {
        z-index: 2;
        @include breakpoint(large) {
          left: 25vw;
        }

        @include breakpoint(xxlarge) {
          left: 20vw;
        }
      }

      &.level-03 {
        z-index: 2;

        @include breakpoint(large) {
          z-index: 1;
          left: 50vw;
        }

        @include breakpoint(xxlarge) {
          left: 40vw;
        }
      }
    }

    // Change to &:nth-child(2) when adding siteswitch mobile nav
    &:nth-child(1) {
	    pointer-events: auto;
      opacity: 1;
      left: 0;

      @include breakpoint(large) {
        z-index: 3;
      }

      @include breakpoint(xxlarge) {
        padding: 2rem 2rem 2rem 3rem;
      }
    }
  }

  &__list {
    &__item {
      &__anchor {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
          gap: .5rem; // avoid text and icon touch
        align-items: center;
        margin-bottom: 1rem;

        .text {
          color: $c-black;
          font-size: $f-xsmall;
          line-height: $lh-xsmall;
          transition: color $t-animation-fast ease;

            .new-badge {
                font-size: $f-xxsmall;
                line-height: $lh-xxsmall;
                font-weight: $fw-bold;
                color: $c-white;
                background: $c-red;
                padding: 0 .375rem .125rem .375rem;
                border-radius: 999rem;
            }
        }

        .icon {
          svg {
            height: 1.5rem;
            width: 1.5rem;

            path {
              fill: $c-black;
              transition: fill $t-animation-fast ease;
            }
          }
        }

        &:hover,
        &.is-triggered,
        &.active {
          .text {
            color: $c-red;
          }

          .icon {
            svg {
              path {
                fill: $c-red;
              }
            }
          }
        }

        &.active {
          font-weight: $fw-bold;
        }

        &--back {
          justify-content: flex-start;

          .text {
            color: $c-gray-50;
          }

          .icon {
            margin-right: 1rem;

            svg {
              path {
                fill: $c-gray-50;
              }
            }
          }
        }
      }
    }
  }
}
