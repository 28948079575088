.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  background: $cookiemessage-bg;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;

  &.hidden {
    display: none;
  }

  &__content {
    position: absolute;
    width: 100%;
    top: 10vh;
    margin-bottom: 10vh;
    z-index: 10;

    &__inner {
      background: $c-white;
      position: relative;
      padding: 4.5rem 1.5rem 1.5rem;

      @include breakpoint(medium) {
        padding: 5rem 0;
      }

      @include breakpoint(xlarge) {
        padding: 6rem 0;
      }
    }
  }

  &__title {
    color: $c-black;
    font-size: $f-small;
    line-height: $lh-xsmall;

    @include breakpoint(medium) {
      padding-right: rem-calc(132px);
      font-size: $f-medium;
      line-height: $lh-small;
    }

    @include breakpoint(large) {
      padding-right: rem-calc(200px);
    }

    @include breakpoint(xlarge) {
      font-size: $f-large;
      line-height: $lh-medium;
    }

    &__wrap {
      position: relative;
      margin-bottom: 1rem;
      min-height: 1.5rem;

      @include breakpoint(large) {
        min-height: 2.5rem;
      }
    }
  }

  &__close {
    position: absolute;
    z-index: 2;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;

    @include breakpoint(medium) {
      right: 2rem;
      top: 2rem;
    }

    svg {
      path {
        fill: $c-gray-50;
        transition: fill $t-animation-fast ease;
      }
    }

    &:hover {
      svg {
        path {
          fill: $c-black;
        }
      }
    }
  }

  &__text {
    color: $c-black;
    font-size: $f-xxsmall;
    line-height: $lh-xsmall;

    @include breakpoint(medium) {
      font-size: $f-xsmall;
    }

    @include breakpoint(xlarge) {
      font-size: $f-small;
    }

    p,
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    position: relative;
    width: calc(100% + 3rem);
    left: -1.5rem;
    padding-top: 1rem;

    @include breakpoint(medium) {
      width: 100%;
      position: static;
      left: auto;
    }
  }

  &__logo {
    &__image {
      width: rem-calc(100px);
      height: 1.5rem;
      margin-bottom: .5rem;

      @include breakpoint(medium) {
        position: absolute;
        right: 0;
        top: 0;
        margin-bottom: 0;
      }

      @include breakpoint(large) {
        width: rem-calc(167px);
        height: 2.5rem;
      }
    }
  }
}