.ce-header {
  color: $c-black;
  font-size: $f-medium;
  line-height: $lh-small;
  margin-bottom: .5rem;

  @include breakpoint(medium) {
    font-size: $f-large;
    line-height: $lh-medium;
    margin-bottom: 1rem;
  }

  @include breakpoint(xlarge) {
    font-size: $f-xlarge;
    line-height: $lh-large;
    margin-bottom: 2rem;
  }
}