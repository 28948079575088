.product {
  &__related {
    &__slider {

      .slick-list {
        width: calc(100% + .5rem);
        left: -.5rem;

        @include breakpoint(medium) {
          width: calc(100% + 1rem);
          left: -1rem;
        }
      }

      .slick-slide {
        margin-left: .25rem;

        @include breakpoint(medium) {
          margin-left: 1rem;
        }
      }

      &__nav {
        &__dots {
          width: 100%;

	        .slick-dots {
		        display: flex;
		        justify-content: center;

		        > li {
			        margin-left: 1rem;

			        @include breakpoint(xlarge) {
				        margin-left: 1.5rem;
			        }

					// sass-lint:disable nesting-depth
			        &:first-child {
				        margin-left: 0;
			        }

			        > button {
				        outline: 0;
				        text-indent: -9999rem;
				        width: 1rem;
				        height: 1rem;
				        background: $c-gray-10;

				        @include breakpoint(xlarge) {
					        width: 1.5rem;
					        height: 1.5rem;
				        }
			        }

			        &.slick-active {
				        > button {
					        background: $c-gray-30;
				        }
			        }
		        }
	        }
        }
		  // sass-lint:enable nesting-depth

	      // repeating with row 27
	      .slick-dots {
		      display: flex;
		      justify-content: center;

		      > li {
			      margin-left: 1rem;

			      @include breakpoint(xlarge) {
				      margin-left: 1.5rem;
			      }

			      &:first-child {
				      margin-left: 0;
			      }

			      > button {
				      outline: 0;
				      text-indent: -9999rem;
				      width: 1rem;
				      height: 1rem;
				      background: $c-gray-10;

				      @include breakpoint(xlarge) {
					      width: 1.5rem;
					      height: 1.5rem;
				      }
			      }

			      &.slick-active {
				      > button {
					      background: $c-gray-30;
				      }
			      }
		      }
	      }
      }
    }
  }
}
