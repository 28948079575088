.nav-footer {
  &__list {
    &__item {
      &__anchor {
        color: $c-gray-50;
        display: inline-block;
        line-height: $lh-xsmall;
        transition: color $t-animation-fast ease;

        &:hover,
        &:active,
        &:focus,
        &.active {
          color: $c-black;
        }

        &.level-01 {
          font-weight: $fw-bold;
          font-size: $f-small;
          letter-spacing: .0625rem;
          margin-bottom: 1rem;
        }

        &.level-02 {
          font-size: $f-small;
        }
      }

      &.level-01 {
        padding: 1rem 0;
        border-bottom: .125rem solid $c-gray-20;

        @include breakpoint(medium) {
          border-bottom: 0;
          padding: 0;
        }
      }

      &.level-02 {
        margin-bottom: .5rem;

        &.last {
          margin-bottom: 0;
        }
      }
    }
  }
}
