.search {

  &--detail {
    .result-found {
      color: $c-black;
      font-size: $f-xsmall;
      line-height: $lh-xsmall;
      margin-bottom: 1rem;

      @include breakpoint(xlarge) {
        font-size: $f-small;
      }
    }

    .facet-option-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      .facet-option {
        white-space: nowrap;
        margin-right: 1.5rem;
        margin-top: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .solr-pagination {
      margin-top: 1.5rem;

      @include breakpoint(xlarge) {
        margin-top: 3rem;
      }
    }
  }

  &__list {
    &__item {
      display: block;
      border-bottom: .125rem solid $c-gray-10;
      padding: 1.5rem 0;

      @include breakpoint(xlarge) {
        padding: 3rem 0;
      }

      .title {
        color: $c-black;
        transition: color $t-animation-fast ease;
        font-size: $f-small;
        line-height: $lh-xsmall;

        @include breakpoint(xlarge) {
          font-size: $f-medium;
          line-height: $lh-small;
        }
      }

      .text {
        color: $c-black;
        font-size: $f-xsmall;
        line-height: $lh-xsmall;
        margin-top: 1rem;

        @include breakpoint(xlarge) {
          font-size: $f-small;
        }

        .results-highlight {
          color: $c-red;
        }
      }

      &:hover {
        .title {
          color: $c-red;
        }
      }
    }
  }

  &__trigger {
    .open-search {
      display: block;
    }

    .close-search {
      display: none;
    }

    &.is-open {
      .open-search {
        display: none;
      }

      .close-search {
        display: block;
      }
    }
  }

  &__overlay {
    background: $c-gray-5;
    box-shadow: $header-shadow;
    padding: 1.5rem 0;
    opacity: 0;
    position: absolute;
    left: -100%;
    z-index: 0;
    transition: opacity $t-animation-fast ease, z-index $t-animation-fast ease;

    .input--search {
      background: $c-gray-5;
    }

    .search-close-layer {
      content: '';
      display: none;
      position: fixed;
      height: 100vh;
      width: 100vw;
      z-index: -1;
    }

    &.is-open {
      position: relative;
      left: 0;
      z-index: 1;
      opacity: 1;

      .search-close-layer {
        display: block;
      }
    }
  }

  &__autocomplete-results {

    .result-item {
      color: $c-black;
      background: $c-white;
      transition: color $t-animation-fast ease, background-color $t-animation-fast ease;
      cursor: pointer;
      border-bottom: .0625rem solid $c-gray-10;
      padding: .5rem;
      font-size: $f-xsmall;
      line-height: $lh-xsmall;
	    word-break: break-word;

      &.is-active,
      &:hover {
        background-color: $c-gray-5;
      }

      &:last-child {
        border-bottom: 0;
      }

      .form--search--mobile & {
        background-color: $c-gray-5;
        color: $c-gray-50;
        padding: .5rem 0;

        &.is-active,
        &:hover {
          color: $c-black;
        }
      }
    }

    &--absolute {
      position: absolute;
      width: 100%;
      background: $c-white;
      z-index: 2;
      box-shadow: $header-shadow;
    }
  }
}
