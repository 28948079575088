.page-footer {
  background: $c-gray-5;
  border-top: .25rem solid $c-red;
  color: $c-gray-50;

  &__top {
    padding: 1rem 0 0;

    @include breakpoint(medium) {
      padding: 2rem 0 1rem;
    }
  }

  &__bottom {
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      flex-direction: column;

      @include breakpoint(medium) {
        flex-direction: row;
        min-height: 2rem;
        padding: 1rem 0;
        border-top: .125rem solid $c-gray-20;
      }

      @include breakpoint(xlarge) {
        min-height: 2.5rem;
        padding: 1.5rem 0;
      }
    }

    &__copyright {
      //flex: 1;
      padding: .5rem 0 1rem;
      order: 3;
      font-size: $f-xxsmall;
      line-height: $lh-xsmall;

      @include breakpoint(medium) {
        order: 1;
        padding: 0;
      }

        @include breakpoint(large) {
            font-size: $f-xsmall;
        }
    }

    &__socialmedia {
      order: 1;
      display: flex;
      justify-content: center;
      border-bottom: .125rem solid $c-gray-20;
      width: 100%;
      padding: 2rem 0;

        // sass-lint:disable no-important
      @media (min-width: 768px) and (max-width: 860px) {
        left: 45% !important;
      }
        // sass-lint:enable no-important

      @include breakpoint(medium) {
        border-bottom: 0;
        width: auto;
        order: 2;
        padding: 0;
      }

        @include breakpoint(xxlarge) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

      .social-link {
        display: block;
        line-height: 0;
      }

      .icon {
        height: 2.5rem;
        width: 2.5rem;

        @include breakpoint(medium) {
          height: 2rem;
          width: 2rem;
        }

        @include breakpoint(xlarge) {
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      &__item {
        margin-right: 1.5rem;

        @include breakpoint(medium) {
          margin-right: .5rem;
        }

          @include breakpoint(large) {
              margin-right: 1rem;
          }

          @include breakpoint(xxlarge) {
              margin-right: 2rem;
          }

	      &:last-child {

		      margin-right: 0;
	      }
      }
    }

    &__irsap {
      order: 2;
      display: flex;
      align-items: center;
      padding-top: 1rem;

      @include breakpoint(medium) {
        order: 3;
        padding-top: 0;
      }

      &__text {
        margin-right: 1rem;
        font-size: $f-xxsmall;
        line-height: $lh-xsmall;

        @include breakpoint(medium) {
            margin-right: .5rem;
        }

          @include breakpoint(large) {
              font-size: $f-xsmall;
              margin-right: 1rem;
          }
      }

      &__logo {

        &__link {
          display: block;
            line-height: 0;

          .irsap-logo {
            width: auto;
            height: 1.5rem;

            @include breakpoint(medium) {
              height: 1.875rem;
              width: auto;
            }

            @include breakpoint(xlarge) {
              height: 2.5rem;
              width: auto;
            }
          }
        }
      }
    }
  }
}
