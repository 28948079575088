.ce-bigslider {
	$parent: &;

	// sass-lint:disable class-name-format
	&__container {
		position: relative;

		+ .frame-type-mask_textimage,
		+ .frame-type-list,
		+ .frame-type-mask_awards {

			margin-top: 2.5rem;

			@include breakpoint(medium) {
				margin-top: 5rem;
			}

			@include breakpoint(xlarge) {
				margin-top: 6rem;
			}
		}
	}

	// sass-lint:enable class-name-format

	&__item {
		overflow: hidden;

		height: 100%;
		width: 100%;
		position: relative;
	}

	// Image
	&__image {
		position: relative;
		z-index: 1;

		&__wrap {
			position: relative;

			&:before {
				content: '';
				width: 100%;
				height: .25rem;
				background: $c-red;
				z-index: 3;
				position: absolute;
				top: 0;
				left: 0;
			}

			&:after {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: $slider-overlay;
				z-index: 2;

				content: '';
			}
		}
	}

	&--captions {
		& #{$parent}__item {
			height: auto;
		}
	}

	&__caption {
		color: $c-gray-70;
		font-size: $f-xsmall;
		line-height: $lh-xsmall;
		padding: 1em $slider-nav-height-small 0;

		@include breakpoint(medium) {
			font-size: $f-small;
			line-height: $lh-small;
			padding: 1em $slider-nav-height-medium 0;
		}

		@include breakpoint(xlarge) {
			padding: 1em $slider-nav-height-xlarge 0;
		}

		&:empty {
			padding: 0;
		}
	}

	&__content {

		padding: rem-calc(40) 0;
		width: 100%;

		@include breakpoint(medium) {

			padding: 0;

			position: absolute;
            top: 2.25rem;
			bottom: 8rem;
			z-index: 2;
		}

        @include breakpoint(large) {
            top: unset;
        }

		@include breakpoint(xlarge) {
			bottom: 11rem;
		}

		.page-intro & {
			bottom: 2.5rem;

			@include breakpoint(medium) {
				bottom: 4rem;
			}

			@include breakpoint(xlarge) {
				bottom: 6rem;
			}
		}

		&__title {
			font-size: $f-large;
			line-height: $lh-medium;

			@include breakpoint(medium) {

				color: $c-white;
				font-size: $f-xlarge;
				line-height: $lh-large;
			}


            @include breakpoint(large) {

                font-size: $f-xxlarge;
                line-height: $lh-xlarge;
            }

			@include breakpoint(xlarge) {
				margin-bottom: .5rem;
			}
		}

		&__text {
			font-size: $f-xsmall;
			line-height: $lh-xsmall;

			@include breakpoint(medium) {

				color: $c-white;
				font-size: $f-small;
			}

			@include breakpoint(xlarge) {
				font-size: $f-medium;
				line-height: $lh-small;
			}
		}

		&__button {
			margin-top: 1rem;

			@include breakpoint(medium) {
				margin-top: 1.5rem;
			}
		}
	}

	&__nav {
		position: absolute;
		top: 0;
		z-index: 2;
		padding-bottom: 48.75%; // equals height of image
		width: 100%;
		height: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		pointer-events: none;

		&__dots {
			position: absolute;
			bottom: 16px;
			z-index: 1;
			left: 0;
			width: 100%;
			padding: 0 $slider-nav-height-small;

			pointer-events: all;

			@include breakpoint(medium) {
				bottom: 26px;

				padding: 0 $slider-nav-height-medium;
			}

			@include breakpoint(xlarge) {
				bottom: 28px;

				padding: 0 $slider-nav-height-xlarge;
			}

			.slick-dots {
				display: flex;
				justify-content: center;

				> li {
					margin-left: 1rem;

					@include breakpoint(xlarge) {
						margin-left: 1.5rem;
					}

					&:first-child {
						margin-left: 0;
					}

					> button {
						outline: 0;
						box-shadow: $dot-nav-shadow;
						text-indent: -9999rem;
						width: 1rem;
						height: 1rem;
						background: $c-white;
						transition: opacity $t-animation-fast ease;
						opacity: .5;

						@include breakpoint(xlarge) {
							width: 1.5rem;
							height: 1.5rem;
						}
					}

					&.slick-active {
						> button {
							opacity: 1;
						}
					}
				}
			}
		}

		&__button {
			outline: 0;
			display: flex;
			height: $slider-nav-height-small;
			width: $slider-nav-height-small;
			align-items: center;
			justify-content: center;
			background: $c-white;
			position: absolute;
			bottom: 0;
			z-index: 2;

			pointer-events: all;

			@include breakpoint(medium) {
				height: $slider-nav-height-medium;
				width: $slider-nav-height-medium;
			}

			@include breakpoint(xlarge) {
				height: $slider-nav-height-xlarge;
				width: $slider-nav-height-xlarge;
			}

			&--prev {
				left: 0;
			}

			&--next {
				right: 0;
			}

			.icon {
				svg {
					height: 1.125rem;
					width: 1.125rem;

					@include breakpoint(medium) {
						height: 1.5rem;
						width: 1.5rem;
					}

					path {
						transition: fill $t-animation-fast ease;
						fill: $c-black;
					}
				}
			}

			&:hover {
				.icon {
					svg {
						path {
							fill: $c-red;
						}
					}
				}
			}
		}
	}
}
