// sass-lint:disable nesting-depth
.nav-tabs {
  //-webkit-overflow-scrolling: touch;
  //// sass-lint:disable no-misspelled-properties
  //scrollbar-width: none;
  //// sass-lint:enable no-misspelled-properties
  //-ms-overflow-style: none;
  //overflow-x: scroll;
  //overflow-y: hidden;

  //&::-webkit-scrollbar {
  //  display: none;
  //}

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    &__item {
      margin-right: 1rem;
      margin-bottom: 1rem;

      @include breakpoint(medium) {
        margin-right: 2.5rem;
      }

      &:last-child {
        //border-right: 2rem solid $c-white;
        margin-right: 0;
      }

      &__anchor {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: .25rem solid $c-black;
        padding-bottom: .5rem;
        transition: border-bottom $t-animation-fast ease;

        .icon {
          margin-right: .5rem;
          height: 1rem;
          width: 1rem;

          @include breakpoint(xlarge) {
            height: 1.5rem;
            width: 1.5rem;
          }

          path {
            fill: $c-black;
            transition: fill $t-animation-fast ease;
          }
        }

        .text {
          color: $c-black;
          font-size: $f-xsmall;
          font-weight: $fw-bold;
          line-height: $lh-xsmall;
          white-space: nowrap;
          transition: color $t-animation-fast ease;

          @include breakpoint(xlarge) {
            font-size: $f-small;
          }
        }

        &:hover,
        &.is-active {
          border-bottom: .25rem solid $c-red;

          .icon {
            path {
              fill: $c-red;
            }
          }

          .text {
            color: $c-red;
          }
        }
      }
    }
  }
}
// sass-lint:enable nesting-depth
