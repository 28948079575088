.sticky-contact {
    $self: &;
    cursor: pointer;
    position: fixed;
    bottom: .625rem;
    right: .625rem;
    z-index: 4;
    background: $c-mint;
    color: $c-white;
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    height: 3rem;
    padding: 0 .75rem 0 0;
    border-radius: 6.25rem;

    @include breakpoint(xlarge) {
        height: 3.375rem;
        padding: 0 1rem 0 0;
    }

    &__link {
        display: flex;
        gap: .5rem;
        align-items: center;
        justify-content: center;
        color: $c-white;
        padding: .75rem 0 .75rem 1.25rem;

        @include breakpoint(xlarge) {
            gap: 1rem;
            padding: .8125rem 0 .8125rem 1.25rem;
        }
    }

    > .icon {
        display: none;
    }

    .icon {
        svg {
            width: 22px;
            height: 22px;

            @include breakpoint(xlarge) {
                width: 28px;
                height: 28px;
            }
        }

    }

    .text {
        display: block;
        font-size: $f-xxsmall;
        line-height: $lh-xxsmall;
        font-weight: $fw-bold;
        white-space: nowrap;

        @include breakpoint(xlarge) {
            font-size: $f-xsmall;
            line-height: $lh-xsmall;
        }
    }

    .close-btn {
        line-height: 0;
        opacity: .5;
    }

    &:hover {
    color: $c-white;
    }

    &:focus,
    &:active {
        color: $c-white;
    }

    // Closed styling
    &.is-closed {
        padding: 13px;

        > .icon {
            display: block;
        }

        .close-btn,
        #{$self}__link {
            display: none;
        }
    }

}
