// FYI: There are two intro versions that use the same classes as ce-bigslider and ce-text-image
.page-intro {
  + .page-breadcrumb {
    + .frame {
      margin-top: 2.5rem;

      @include breakpoint(medium) {
        margin-top: 0;
      }
    }
  }

  &--no-image {
    margin-top: 2.5rem;

    @include breakpoint(medium) {
      margin-top: 0;
    }
  }
}