.page-header {
  position: fixed;
  width: 100%;
  z-index: 2;

  &__top {
    background: $c-gray-5;
    position: relative;

    &__inner {
      margin: 0 auto;
      max-width: rem-calc(1920px);

      @include breakpoint(xxlarge) {
        padding: 0 3rem;
      }
    }
  }

  &__bottom {
    background: $c-white;
    box-shadow: $header-shadow;
    position: relative;
    z-index: 2;

    &__inner {
      margin: 0 auto;
      max-width: rem-calc(1920px);
      display: flex;
      padding: 0 1.5rem;
      justify-content: space-between;
      align-items: center;
      height: rem-calc(72px);

      @include breakpoint(large) {
        height: rem-calc(96px);
      }

      @include breakpoint(xxlarge) {
        padding: 0 3rem;
      }

      .logo {
        &__link {
          display: block;
          line-height: 0;
        }

        &__image {
          width: rem-calc(100px);
          height: 1.5rem;

          @include breakpoint(large) {
            width: rem-calc(167px);
            height: 2.5rem;
          }

        }
      }

      &__left {
        display: flex;
        align-items: center;

        .item {
          margin-right: 1.5rem;

          @include breakpoint(large) {
            margin-right: 2.5rem;
          }

          @include breakpoint(xxlarge) {
            margin-right: 5rem;
          }

          &.last {
            margin-right: 0;
          }

          &__link {
            cursor: pointer;
            display: flex;

            .icon {
              line-height: 0;
              position: relative;

              svg {
                height: 1.5rem;
                width: 1.5rem;

                path {
                  fill: $c-black;
                  transition: fill $t-animation-fast ease;
                }
              }

              @include breakpoint(large) {
                margin-right: 1rem;
              }

              .batch {
                position: absolute;
                right: -.5rem;
                top: -.5rem;
                z-index: 1;
                background: $c-red;
                display: flex;
                color: $c-white;
                font-size: $f-xxxsmall;
                font-weight: $fw-bold;
                line-height: $lh-xxsmall;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: rem-calc(20px);
                height: rem-calc(20px);
                font-style: normal;
              }

            }

            .icon-nav-opener {
              width: 1.5rem;
              height: 1.5rem;
              position: relative;

              &__inner,
              &:before,
              &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: $c-black;
                position: absolute;
                left: 0;
                transform-origin: left center;
                transition: background $t-animation-fast ease-in-out, width $t-animation-fast ease-in-out, opacity $t-animation-fast ease-in-out, transform $t-animation-fast ease-in-out, top $t-animation-fast ease-in-out, bottom $t-animation-fast ease-in-out;
              }

              &__inner {
                top: 11px;
              }

              &:before {
                top: 3px;
              }

              &:after {
                bottom: 3px;
              }

            }

            .text {
              color: $c-black;
              font-weight: $fw-bold;
              font-size: $f-small;
              line-height: $lh-xsmall;
              transition: color $t-animation-fast ease;
              letter-spacing: .0625rem;
            }

            &:hover,
            &.active {

              .icon-nav-opener__inner {
                background: $c-red;
              }

              .icon-nav-opener {
                &:before,
                &:after {
                  background: $c-red;
                }
              }

              .icon {
                svg {
                  path {
                    fill: $c-red;
                  }
                }
              }

              .text {
                color: $c-red;
              }
            }

            &.is-open {
              .icon-nav-opener {
                &__inner,
                &:before,
                &:after {
                  background: $c-red;
                }

                &__inner {
                  width: 0;
                  opacity: 0;
                }

                &:before {
                  top: 0;
                  transform: rotate(45deg);
                  width: rem-calc(31px);
                }

                &:after {
                  bottom: 0;
                  transform: rotate(-45deg);
                  width: rem-calc(31px);
                }
              }

              .text {
                color: $c-red;
              }
            }
          }
        }
      }
    }
  }
}
