.teaserbox {
  $self: &;
  position: relative;
  border-bottom: .25rem solid $c-red;

  &__list {
    &__item {
      margin-bottom: 2rem;
    }
  }

  &__image {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background: $slider-overlay;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background: $teaserbox-hover;
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity $t-animation-fast ease;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    color: $c-white;
    padding: 1rem;

    @include breakpoint(xlarge) {
      padding: 2rem;
    }

    .title {
      font-size: $f-small;
      line-height: $lh-xsmall;
      margin-bottom: .5rem;

      @include breakpoint(xlarge) {
        font-size: $f-medium;
        line-height: $lh-small;
        margin-bottom: 1rem;
      }
    }

    .text {
      font-size: $f-xsmall;
      line-height: $lh-xsmall;
      margin-bottom: .5rem;

      @include breakpoint(xlarge) {
        font-size: $f-small;
        margin-bottom: 1rem;
      }
    }

    .icon {
      svg {
        path {
          fill: $c-white;
        }
      }
    }
  }

  &:hover {
    #{ $self }__image {
      &:after {
        opacity: 1;
      }
    }
  }
}
