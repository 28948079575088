/* FreeSans */
@font-face {
  font-family: 'FreeSans';
  font-weight: 400;
  font-style: normal;
  src: url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSans.eot');
  src: url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSans.eot?#iefix') format('embedded-opentype'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSans.woff2') format('woff2'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSans.woff') format('woff'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSans.ttf') format('truetype'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSans.svg#FreeSans') format('svg');
}

@font-face {
  font-family: 'FreeSans';
  font-weight: 700;
  font-style: normal;
  src: url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSansBold.eot');
  src: url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSansBold.eot?#iefix') format('embedded-opentype'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSansBold.woff2') format('woff2'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSansBold.woff') format('woff'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSansBold.ttf') format('truetype'),
  url('/typo3conf/ext/ww_project/Resources/Public/Fonts/FreeSansBold.svg#FreeSans') format('svg');
}